<template>
    <div id="app">
        <h2 class="border-bottom pb-2 mb-3">
            <i class="fa fa-bullseye"></i> QSO Report
        </h2>
        <div style="background-color: white; padding:2%;">
            <div class="layout-top-spacing mt-4">
                <div class="widget-content widget-content-area component-card_1 mb-4">
                    <div class="row">
                        <div class="col-lg-1 col-md-3">
                            <div class="tabs-wrap">
                                <h6>View</h6>
                                <ejs-dropdownlist cssClass="e-outline" id='sample-list3' :fields="{ text: 'text', value: 'value' }" :dataSource='views' :mode='true' v-model="filters.view" placeholder='View'></ejs-dropdownlist>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-3">
                            <div class="tabs-wrap">
                                <h6>Date Range</h6>
                                <div class="wrap">
                                    <!--                                <ejs-datepicker cssClass="e-outline" id='startPicker' placeholder="start" v-model="filters.date" ></ejs-datepicker>-->
                                    <ejs-daterangepicker cssClass="e-outline" v-model="filters.date"></ejs-daterangepicker>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-3">
                            <div class="control-styles">
                                <h6>Country</h6>
                                <ejs-dropdownlist cssClass="e-outline" id='sample-list3' v-model="filters.country" :dataSource='countries' :fields='fields' :mode='true' placeholder='Country' :allowFiltering="true" :change='loadStates'></ejs-dropdownlist>
                            </div>
                        </div>
                        <div class="col-lg-1 col-md-3">
                            <div class="control-styles">
                                <h6>State</h6>
                                <ejs-dropdownlist cssClass="e-outline" id='sample-list3' v-model="filters.state" :dataSource='states' :fields='fields' :mode='true' placeholder='State' :allowFiltering="true" :change='loadCities'></ejs-dropdownlist>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-3">
                            <div class="control-styles">
                                <h6>City</h6>
                                <ejs-dropdownlist cssClass="e-outline" id='sample-list3' v-model="filters.city" :dataSource='cities' :mode='true' placeholder='City' :fields="city_fields" :allowFiltering="true"></ejs-dropdownlist>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-3">
                            <div class="control-styles">
                                <h6>Search</h6>
                                <span class="e-input-group e-control-wrapper e-outline e-valid-input e-datetime-wrapper">
                                        <input class="e-control e-textbox e-outline e-lib e-input e-keyboard" placeholder="Search IP/Link" v-model="filters.search" />
                                    </span>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-2">
                            <div class="tabs-wrap" style="padding-top: 34px;">
                                <button class="e-control e-progress-btn e-lib e-btn e-spin-right" data-ripple="true" aria-label="SEARCH progress" style="color: white; background-color: rgb(56, 56, 56);" @click="filter">
                                    <span class="e-btn-content">SEARCH</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ejs-grid ref="grid" :dataSource="data" :query="query" :allowPaging="true" :toolbar='toolbar' :toolbarClick='toolbarClick' :excelExportComplete='exportComplete' :pdfExportComplete='exportComplete' :allowFiltering="true" :allowExcelExport="true" :allowPdfExport="true" :allowSorting="true" :pageSettings="pageSettings">
                <e-columns v-if="filters.view === ''">
                    <e-column field="date" headerText="Date" text-Align="Left" :format="{type: 'dateTime', skeleton: 'medium'}"></e-column>
                    <e-column field="country" headerText="Country" text-Align="Left" ></e-column>
                    <e-column field="state" headerText="State" text-Align="Left"></e-column>
                    <e-column field="city" headerText="City" text-Align="Left"></e-column>
                    <e-column field="link" headerText="Link" text-Align="Left"></e-column>
                    <e-column field="ip" headerText="IP Address" text-Align="Left" width="130"></e-column>
                    <e-column field="count" headerText="Need" text-Align="Left" width="80"></e-column>
                </e-columns>
                <e-columns v-else-if="filters.view === 'summary'">
                    <e-column field="hour" headerText="Hour" text-Align="Left" ></e-column>
                    <e-column field="count" headerText="Need" text-Align="Left"></e-column>
                    <e-column field="sessions" headerText="Sessions" text-Align="Left"></e-column>
                </e-columns>
                <e-columns v-else-if="filters.view === 'state'">
                    <e-column field="country" headerText="Country" text-Align="Left" ></e-column>
                    <e-column field="state" headerText="State" text-Align="Left"></e-column>
                    <e-column field="needs" headerText="Need" text-Align="Left"></e-column>
                    <e-column field="sessions" headerText="Sessions" text-Align="Left"></e-column>
                </e-columns>
                <e-columns  v-else-if="filters.view === 'city'">
                    <e-column field="country" headerText="Country" text-Align="Left" ></e-column>
                    <e-column field="state" headerText="State" text-Align="Left"></e-column>
                    <e-column field="city" headerText="City" text-Align="Left"></e-column>
                    <e-column field="needs" headerText="Need" text-Align="Left"></e-column>
                    <e-column field="sessions" headerText="Sessions" text-Align="Left"></e-column>
                </e-columns>
                <e-columns v-else-if="filters.view === 'hour'">
                    <e-column field="hour" headerText="Hour" text-Align="Left" ></e-column>
                    <e-column field="needs" headerText="Need" text-Align="Left"></e-column>
                    <e-column field="sessions" headerText="Sessions" text-Align="Left"></e-column>
                </e-columns>
            </ejs-grid>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import VueChartsCSS from "vue.charts.css";
import axios from "axios";
import moment from "moment-timezone";
import { GridPlugin, Page, PdfExport, ExcelExport, Sort, Group,Toolbar} from '@syncfusion/ej2-vue-grids';
import { DataManager, WebApiAdaptor, Query } from "@syncfusion/ej2-data";
import { ClickEventArgs } from "@syncfusion/ej2-vue-navigations";
import {DropDownListPlugin} from "@syncfusion/ej2-vue-dropdowns";
import {DatePickerPlugin, DateRangePickerPlugin} from "@syncfusion/ej2-vue-calendars";

Vue.use(GridPlugin);

const querystring = require('querystring');
Vue.use(VueChartsCSS);
Vue.use(DropDownListPlugin);
Vue.use(DatePickerPlugin);
Vue.use(DateRangePickerPlugin);

export default {
  name: "qso",
  props: ["user"],
  data: function () {
    return {
        //data:{},
        fields: {text: 'name', value: 'code'},
        city_fields: {text: 'city_name'},
        countries: [],
        cities: [],
        states: [],
        views: [
            {text: 'Default', value: ""},
            {text: 'Summary', value: "summary"},
            {text: 'State', value: "state"},
            {text: 'City', value: "city"},
            {text: 'Hour', value: "hour"}
        ],
        filters: {
            view: '',
            date: {
                start: moment(Date.now()).startOf("day").toDate(),
                end: moment(Date.now()).endOf("day").toDate()
            },
            country: "",
            state: null,
            city: null
        },
        temp: {
            date: {
                start: moment(Date.now()).startOf("day").toDate(),
                end: moment(Date.now()).endOf("day").toDate()
            }
        },
        data: new DataManager({
            url: `${this.$root.serverUrl}/admin/reports/qso`,
            adaptor: new WebApiAdaptor(),
            crossDomain: true,
            headers:[{ 'Authorization': 'Bearer ' + this.$cookies.get('accessToken') }]
        }),
        toolbar: ['ExcelExport', 'PdfExport'],
        pageSettings: { pageSize: 50, pageSizes: [50, 100, 500] },
        form: { from: "", to: "", view: "", sorting: { date: -1 } },
        preloader: false,
        request: Object.assign({ apikey: this.$root.apikey }, this.form),
        filterOptions: {
            type: 'Menu'
        }
    };
  },

  created: function () {
    this.$root.preloader = false;
    // this.load(); Loads from FILES.JS || line 2965
    this.loadCountries();
  },
  computed: {
    getView() {
      return this.form.view;
    },
    query() {
        let filters = Object.assign({}, this.temp);
        return new Query().addParams('filters', JSON.stringify(filters));
    }
  },
  watch: {
    getView(newVal, oldVal) {
      // console.log(`View change event: old[${oldVal}], new[${newVal}], `, this);
      if (oldVal !== newVal) {
        // this.loader();
      }
      this.loadCountries();
    // this.load();
    this.$root.preloader = false;
    }
  },
  provide: {
      grid: [Page, Sort,Group,Toolbar,PdfExport,ExcelExport]
  },
  beforeDestroy() {},
  destroyed() {
    window.removeEventListener("keydown", this.windowListener);
  },
  methods: {
    filter() {
        let filters = Object.assign({}, this.filters);
        this.view = this.filters.view
        this.temp = filters;
    },
    toolbarClick: function(args = ClickEventArgs) {
    this.$refs.grid.showSpinner();
    switch (args.item.text) {
      /* tslint:disable */
      case 'Excel Export':
        (this.$refs.grid).excelExport(this.getExcelExportProperties());
      break;
      /* tslint:enable */
      case 'PDF Export':
      (this.$refs.grid).pdfExport(this.getPdfExportProperties());
      break;
    }
    },
    exportComplete() {
    this.$refs.grid.hideSpinner();
    },
    getDate: () => {
    let date = '';
    date += ((new Date()).getMonth().toString()) + '/' + ((new Date()).getDate().toString());
    return date += '/' + ((new Date()).getFullYear().toString());
    },
    getExcelExportProperties: function() {
    return {
      fileName: "qso_export.xlsx"
    };
    },
    getPdfExportProperties: function() {
    return {

      fileName: "qso_export.pdf"
    };
    },
    loadCountries() {
    axios.get(`${this.$root.serverUrl}/admin/countries`).then( (resp) => {
      //Store the stats
      this.countries = resp.data.data.filter(e => e.code === 'US');
    });
    },
    loadStates(args) {
    let request = {filters: {country_iso_code: args.value}};
    return axios
      .get(`${this.$root.serverUrl}/admin/geos/state`, { params: request })
      .then(
          function (resp) {
              //Store the stats
              this.states = resp.data ? resp.data.data : [];
          }.bind(this)
      );
    },
    loadCities(args) {
      let request = {filters: {country_iso_code: 'US', subdivision_1_iso_code: args.value}};
      return axios
          .get(`${this.$root.serverUrl}/admin/geos/city`, { params: request })
          .then(
              function (resp) {
                  //Store the stats
                  this.cities = resp.data ? resp.data.data : [];
              }.bind(this)
          );
  },
    windowListener: function (e) {
    if (e.keyCode === 13 && !this.processing) {
    // this.load();
    }
    },
    convertHMS: (value) => {
    const sec = parseInt(value, 10); // convert value to number if it's string
    let hours = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
    let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    if (hours < 10) {
    hours = "0" + hours;
    }
    if (minutes < 10) {
    minutes = "0" + minutes;
    }
    if (seconds < 10) {
    seconds = "0" + seconds;
    }
    return hours + ":" + minutes + ":" + seconds; // Return is HH : MM : SS
    },
    serialize: function(obj) {
    var str = [];
    for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
    return str.join("&");
    },
    exportToExcel: function () {
    // this.$root.preloader = true;
    //Load the catchers
    var request = Object.assign({ apikey: this.$root.apikey }, this.form);

    this.eLoading = true;
    this.preloader = this.$root.preloader = true;

    return axios
    .get(`${this.$root.serverUrl}/static/ga/export`, {
    params: request,
    json: true,
    })
    .then(async (resp) => {
    if (resp == undefined || resp.length == 0) {
    // this.datasource = { total: 0, data: [] };
    this.eLoading = false;
       this.preloader = this.$root.preloader = false;
    } else {
      //request = this.serialize(resp);
      //window.location = `${this.$root.serverUrl}/static/ga/export?${request}`
      //this.eLoading = false;
     // this.preloader = this.$root.preloader = false;
    }
    this.eLoading = false;
    this.preloader = this.$root.preloader = false;
    });
    },
    loader: function () {
    //  this.$root.preloader = true;

    //Load the data
    this.page = this.page < 1 ? 1 : this.page;
    var page = 0;
    if (
    event &&
    event.type == "click" &&
    event.currentTarget &&
    event.currentTarget.className.indexOf(
    this.bootstrapPaginationClasses.button
    ) > -1
    ) {
    //Pagination triggered event
    console.log(
    `Pagination event: ${this.page}: ${event.currentTarget.className}`
    );
    page = this.page ? (this.page === 1 ? 0 : Number(this.page - 1)) : 0;
    } else page = 0;
    var request = Object.assign({ apikey: this.$root.apikey }, this.form);
    request.page = page;
    request.max = this.max;
    // this.preloader = this.$root.preloader = true;
    this.sLoading = true;

    this.data = new DataManager({
          url: `${this.$root.serverUrl}/static/qso`,
          adaptor: new WebApiAdaptor(),
          crossDomain: true,
          query: [request]
      })
    request = querystring.stringify(request);
    this.data.setDefaultQuery(request);
    this.data.update();
    this.sLoading = false;
    this.preloader = this.$root.preloader = false;
    },
    load: function () {
    //  this.$root.preloader = true;

    //Load the data
    this.page = this.page < 1 ? 1 : this.page;
    var page = 0;
    if (
    event &&
    event.type == "click" &&
    event.currentTarget &&
    event.currentTarget.className.indexOf(
    this.bootstrapPaginationClasses.button
    ) > -1
    ) {
    //Pagination triggered event
    console.log(`Pagination event: ${this.page}: ${event.currentTarget.className}`);

    page = this.page ? (this.page === 1 ? 0 : Number(this.page - 1)) : 0;
    } else page = 0;
    var request = Object.assign({ apikey: this.$root.apikey }, this.form);
    request.page = page;
    request.max = this.max;
    // this.preloader = this.$root.preloader = true;
    this.sLoading = true;

    this.data = new DataManager({
    url: `${this.$root.serverUrl}/static/qso`,
    adaptor: new WebApiAdaptor(),
    crossDomain: true,
    query: [request]
    })
    return axios
    .get(`${this.$root.serverUrl}/static/qso`, {
    params: request,
    json: true,
    })
    .then(async (resp) => {
    if (resp == undefined || resp.length == 0) {
    this.datasource = { total: 0, data: [] };
    this.sLoading = false;
         this.preloader = this.$root.preloader = false;
    } else {
    this.datasource = resp.data.Items || { total: 0, data: [] };
    this.sLoading = false;
         this.preloader = this.$root.preloader = false;
    }
    this.sLoading = false;
    this.preloader = this.$root.preloader = false;
    });
    },
    toggleAdvanceFilter() {
    return (this.advanceFilter = !this.advanceFilter);
    },
    History(item) {
    this.modalData = item.data;
    },
    toDate(item) {
      return moment(item).format('MM-DD-YYYY')
    }
  },
  
};
</script>

<style>
@import "../../../node_modules/@syncfusion/ej2-vue-grids/styles/bootstrap4.css";
</style>

<style scoped>
#qso .charts-css.bar {
  height: 200px;
  max-width: 300px;
  margin: 0 auto;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 614px;
    margin: 1.75rem auto;
  }
}
.e-spinner-pane .e-spinner-inner {
    top: 16% !important;
}
</style>
